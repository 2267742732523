<template>
    <div>
        <div class="fullwidth vh-100">

            <div class="transferflow-main">

                <div class="row transferflow-main-nav bg-info p-20 flex-left-right">
                    <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 justify-lg-end justify-start pl-5">
                        <img style="max-width:140px" src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>
                    </div>

                </div>
                <div class="transferflow-main-content p-20">
                    <main class="p-10 w-100">
                        <div class="row p-20" style="background:white;">


                            <router-view></router-view>


                        </div>
                    </main>

                    <!-- Start Footer Area -->
                    <!--TODO hide on mobile-->
                    <footer class="dashboard-main-footer">
                        <div class="footer-area-bottom">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 col-xs-12">
                                        <div class="copyright">
                                            <p>
                                                ©2021
                                                <a href="#">TransCrypt</a> All Rights Reserved
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                    <!-- End Footer Area -->
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        computed: {

        },
        watch : {

        },
        beforeMount() {

        },
        mounted() {

        },
        methods: {


        }
    }
</script>
<style scoped>
    .step-text {
        cursor: pointer;
    }
</style>
